import React from "react";
import { Link, graphql } from "gatsby";
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { renderToStaticMarkup } from "react-dom/server";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Faq from "../components/faq-section";

const FaqTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, seo_content } = data.contentfulPage;
  const faqs = data.allContentfulFaqRubrique;
  const questions = data.allContentfulFaqQuestionEtReponse;

  function renderToSafeHtml(content) {
    const jsx = renderRichText(content);
    const html = renderToStaticMarkup(jsx);
    return html.replace(/"/g, '\\"').replace(/(\r\n|\n|\r)/gm, "");
  }

  return (
    <Layout>
      <Seo title={title} description={main_title}>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${questions.nodes.map(
                  (question) =>
                    `{
                      "@type": "Question",
                      "name": "${question.question}",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${renderToSafeHtml(question.answer)}"
                      }
                    }`
                )}
              ]
            }
          `}
        </script>
      </Seo>
      <section className="section section-gradient">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <p className="paragraph font-weight-bold text-yellow text-center">
                {title}
              </p>
              <h1 className="text-center">{main_title}</h1>
              <hr />
              {faqs.nodes.map((faq) => (
                <Faq title={faq.title} questions={faq.questions} key={faq.contentful_id} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blue text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              {renderRichText(seo_content, {
                renderNode: {
                  [INLINES.HYPERLINK]: (node, children) => {
                    return (
                      <a href={node.data.uri} target="_blank" rel="noreferrer">
                        {children}
                      </a>
                    );
                  },
                  [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                    return (
                      <Link to={`/${context.locale}/${node.data.target.slug}/`}>
                        {children}
                      </Link>
                    );
                  },
                },
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FaqTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      node_locale
      main_title
      seo_content {
        raw
        references {
          contentful_id
          slug
        }
      }
    }
    allContentfulFaqRubrique(
      filter: { node_locale: { eq: $locale }, faq_page: { eq: true } }
      sort: { fields: order }
    ) {
      nodes {
        contentful_id
        title
        questions {
          contentful_id
          question
          answer {
            raw
          }
        }
      }
    }
    allContentfulFaqQuestionEtReponse(
      filter: {
        node_locale: { eq: $locale }
        faq___rubrique: { elemMatch: { faq_page: { eq: true } } }
      }
    ) {
      nodes {
        contentful_id
        question
        answer {
          raw
        }
      }
    }
  }
`;
